/**
 * Created by loxone on 22.04.16.
 */
'use strict';

var MusicServerEnum = {
    RESET_API_VERSION: {
        apiVersion: [0, 0],
        firmwareVersion: "0.0.0.0"
    },
    UPNP_GROUPING_ALLOWED: false,
    // UPNP Zones can't be grouped anymore (https://www.wrike.com/open.htm?id=100225136)
    // Used in AudioserverComp to Identify what Feature is supported since what version.
    Features: {
        THIS_APP: {
            api: "1.0"
        },
        AWESOME_FEATURE: {
            api: "3.2",
            firmware: "8.0.6.1"
        },
        DYNAMIC_INPUTS: {
            api: "1.1",
            firmware: "0.0.6.9"
        },
        LIBRARY_PLAY: {
            api: "1.1",
            firmware: "0.0.6.9"
        },
        PAGED_QUEUE: {
            api: "1.1",
            firmare: "0.0.6.23"
        },
        ADD_ZONEFAV_DIRECT: {
            api: "1.1",
            firmware: "1.0.9.16"
        },
        DISABLE_RADIOS: {
            api: "1.1",
            firmware: "1.0.9.16"
        },
        DISABLE_AIRPLAY: {
            api: "1.1",
            firmware: "1.0.9.18"
        },
        SEARCH_RADIO: {
            api: "1.1",
            firmware: "0.0.7.21"
        },
        GETMEDIAFOLDER_ERROR: {
            api: "1.1",
            firmware: "1.0.10.8"
        },
        REMOVE_GROUP_MASTER: {
            api: "1.1",
            firmware: "0.0.0.0"
        },
        ADD_EXTERNAL_ID: {
            api: "1.2",
            firmware: "0.0.0.0"
        },
        SEARCH_LIB_IMPROVED: {
            api: "1.3",
            firmware: "1.0.11.27"
        },
        // the first with tags!
        SEARCH_SERVICES_IMPROVED: {
            api: "1.4",
            firmware: "1.0.11.27"
        },
        SEARCH_ALL: {
            api: "9.9",
            firmware: "99.99.99.99"
        },
        LIB_SCAN_HANDLING: {
            api: "1.1",
            firmware: "1.0.12.01"
        },
        BG_LIB_SCANING: {
            api: "1.3",
            firmware: "1.1.9.21"
        },
        // the app only needs to respond to an scan end.
        NEW_LOGIN_HANDLING: {
            api: "1.3",
            firmware: "1.1.4.20"
        },
        FULL_DIALOG_SUPPORT: {
            api: "1.3",
            firmware: "1.1.5.12"
        },
        EQUALIZER: {
            api: "1.3",
            firmware: "1.1.5.30"
        },
        NEW_PLAYLISTS: {
            api: "1.6",
            firmware: "1.1.12.03"
        },
        SEARCHABLE_PLAYLISTS: {
            api: "1.6",
            firmware: "1.1.12.03"
        },
        SEARCHABLE_GENRES: {
            api: "3.0",
            firmware: "9.1.11.29"
        },
        //TODO-woessto: https://www.wrike.com/open.htm?id=122941687
        NEW_SERVICE_PLAY: {
            api: "1.3",
            firmware: "1.1.6.9"
        },
        // new versions expect a service-play with the UID (cmd + user)
        REWORKED_GROUPING: {
            api: "1.6",
            firmware: "1.1.12.03"
        },
        // current source will be correctly set by musicserver/miniserver. Earlier versions did not reset the source
        // when something else than a zone favorite was played.
        AUDIO_ZONE_SOURCE: {
            api: "1.3",
            firmware: "1.1.6.21"
        },
        INPUT_ZONE_FAVS: {
            api: "1.6",
            firmware: "2.4.1.20"
        },
        // lineIn can be added directly without queue.
        DISSOLVE_GROUP: {
            api: "1.6",
            firmware: "1.1.12.03"
        },
        // a single unsync command dissolves a whole group.
        PLAY_WHOLE_LIST: {
            api: "1.4",
            firmware: "1.1.10.18"
        },
        // when a single item is selected, the whole list is played.
        IMPROVED_REQUESTS: {
            api: "1.4",
            firmware: "1.1.11.02"
        },
        // the requests have been improved, they are faster now, no need to slow down in the app.
        SAVE_CURRENT_PLAYING: {
            api: "1.6",
            firmware: "1.1.12.03"
        },
        PRETTY_RADIO_ICONS: {
            api: "1.6",
            firmware: "1.1.12.09"
        },
        // the music server provides pretty radio icons on its own.
        VOICE_RECORDING: {
            api: "1.6",
            firmware: "2.3.08.21"
        },
        V2_FIRMWARE: {
            api: "1.6",
            firmware: "2.0.0.0"
        },
        GLOBAL_SEARCH: {
            api: "1.6",
            firmware: "2.3.09.02"
        },
        NAS_LIBRARY: {
            api: "1.6",
            firmware: "2.3.10.15"
        },
        QUEUE_V2: {
            api: "1.6",
            firmware: "2.3.10.22"
        },
        SPOTIFY_CONNECT: {
            api: "1.6",
            firmware: "2.4.1.8"
        },
        INPUT_CHANGE_EVENT: {
            api: "1.6",
            firmware: "2.4.1.13"
        },
        COPY_ZONE_FAVS: {
            api: "1.6",
            firmware: "2.4.1.13"
        },
        RENAME_PLAYLIST: {
            api: "1.6",
            firmware: "2.4.1.23"
        },
        REINDEX_LIB: {
            api: "1.6",
            firmware: "2.4.1.29"
        },
        SERVICE_FOLLOW: {
            api: "1.6",
            firmware: "2.4.12.3"
        },
        COMBINED_GROUP_COMMANDS: {
            api: "1.6",
            firmware: "2.6.0.0"
        },
        UPDATED_QUEUE_MOVE: {
            api: "1.6",
            firmware: "2.6.10.20"
        },
        DELAYED_QUEUE_EVENTS: {
            api: "1.6",
            firmware: "2.6.10.31"
        },
        LOCAL_PL_UNIQUE_ID: {
            api: "1.6",
            firmware: "2.6.11.18"
        },
        // move and remove in local playlists based on )
        DYNAMIC_GROUPS: {
            api: "1.6",
            firmware: "2.7.5.24"
        },
        CUSTOM_STREAMS: {
            api: "1.6",
            firmware: "2.8.07.05"
        },
        FIXED_ZONE_ACCOUNTS: {
            api: "1.6",
            firmware: "2.8.07.05"
        },
        SOUNDSUIT_INTEGRATION: {
            api: "1.6",
            firmware: "2.8.07.05" //TODO-woessto: adopt
        },
        SPOTIFY_SOUNDSUIT_NEW_LOGIN_FLOW: {
            api: "1.6", 
            firmware: "15.3.11.20"
        }
    },
    // NOTE: The MusicServer V1 expects "noUser" (note the upper cased "U")!
    //       Some webservices will remove this string from the URL with a simple replace function ('url.replace("noUser/", "")') because the user is not needed (Tunein, Local library)
    //       By sending "nouser" this function fails and thus won't remove the user property "noUser" works with both v1 and v2 MusicServers
    //       All uses of "nouser" and "noUser" have been unified and are using this enum now to prevent such issues in the future
    NOUSER: 'noUser',
    // identifier whenever there is a target without a user.
    DEFAULT_RQ_SIZE: 50,
    // how many items per request
    ReloadCause: {
        // the reload is caused by..
        STOPPED: 'stopped',
        // an stop-event, don't reload
        RESUMED: 'resumed',
        // an resume-event, now data can be reloaded.
        CHANGED: 'changed',
        // someone else changing the content.
        USER_CHANGED: 'userChanged',
        // someone else changing the content.
        USER_DELETE: 'userDeleted',
        // someone else changing the content.
        WCHANGE: 'willChange',
        // someone will be changing the content soon.
        RENAMED: 'renamed',
        // someone else changing the name of the container
        CONTENT_UPDATED: 'updated',
        //  the reload is caused because the content of the UI was updated (e.g. library rescan)
        ITEM_ADDED: 'added',
        //  the reload is caused because an item has been added to the container
        DELETE: 'delete',
        //  the reload is caused because an item has been deleted
        CREATE: 'create' //  the reload is caused because an item has been created

    },
    GroupType: {
        FIXED: 0,
        DYNAMIC: 1
    },
    ClientType: {
        PHYSICAL: 0,
        UPNP: 1,
        RPI: 2,
        // Raspberry Pi extension (Development only)
        EXTENSION: 3 // Moonshine (Musicserver v2)

    },
    // Others
    AudioType: {
        BLUETOOTH: 6,
        // can only see the currently playing item, but not what comes up next. prev/next can be used
        SPOTIFY_CONNECT: 5,
        AIRPLAY: 4,
        LINEIN: 3,
        PLAYLIST: 2,
        STREAM: 1,
        FILE: 0
    },
    FileType: {
        SCREEN_STATE_SHORTCUT: -1,
        UNDEFINED: 0,
        LOCAL_DIR: 1,
        LOCAL_FILE: 2,
        LOCAL_PLAYLIST: 3,
        ROOM_FAVORITE: 4,
        FAVORITE: 5,
        HW_INPUT: 6,
        PLAYLIST_BROWSABLE: 7,
        // can be browsed, cannot be edited or removed, can be followed (if from Spotify)
        SEARCH: 8,
        FUNCTION: 9,
        TEXT: 10,
        PLAYLIST_EDITABLE: 11,
        // can be browsed, edited or removed
        FOLLOWED_PLAYLIST: 12 // can be browsed, cannot be edited, can be unfollowed

    },
    ControlContentSectionId: {
        START: "start",
        MENU: "menu"
    },
    ControlContentMenuId: {
        // Also defines the default sorting order!
        FAVORITES: "start-favs",
        HISTORY: "start-history",
        SPOTIFY_PLAYLISTS: "start-spotify_playlists",
        PLAYLISTS: "menu-playlists",
        RADIO: "menu-radio",
        SPOTIFY: "menu-spotify",
        SOUNDSUIT: "menu-soundsuit",
        LIB: "menu-lib",
        LINE_IN: "menu-lineIn",
        ANNOUNCEMENT: "menu-announcement"
    },
    ControlContentIdentifiers: {
        SEARCH: 'search',
        HOME: 'home',
        ZONE_FAVORITES: 'zoneFavs',
        SHORTCUTS: 'shortcuts',
        LOXONE: 'loxone',
        LOXONE_FAVS: "loxone.favs",
        LOXONE_PLAYLISTS: "loxone.playlists",
        LOXONE_LIB: "loxone.lib",
        LOXONE_LINE_IN: "loxone.lineIn",
        LOXONE_VOICE_RECORDER: "loxone.voiceRecorder",
        LOXONE_ZONE_GROUPS: "loxone.zoneGroups",
        SPOTIFY: 'spotify',
        SOUNDSUIT: 'soundsuit',
        TUNE_IN: 'tuneIn',
        SETTINGS: 'settings'
    },
    EventIdentifier: {
        AUDIO: {
            GENERAL: "audio_event",
            QUEUE: 'audio_queue_event',
            SYNC: 'audio_sync_event'
        },
        RELOAD_APP: 'reloadmusicapp_event',
        GOING_TO_STANDBY: 'goingtostandby_event',
        POPUP: 'popup_event',
        DIALOG: 'dialog_event',
        // Replaces Popup Event
        SERVICE_CFG_ERROR: 'serviceconfigerror_event',
        LIBRARY: {
            RESCAN: 'rescan_event'
        },
        ROOMFAV_CHANGED: "roomfavchanged_event",
        FAVORITES_CHANGED: "favoriteschanged_event",
        PLAYLIST_CHANGED: "playlistchanged_event",
        // a single playlist has changed
        INPUT_CHANGED: "lineinchanged_event",
        MASTER_VOLUME: "mastervolumechanged_event",
        RADIOS_CHANGED: "customurl_changed_event",
        SERVICES_CHANGED: "service_changed_event"
    },
    InternalEventIdentifier: {
        ZONE: {
            SYNC_CHANGED: 'syncchanged_event'
        }
    },
    GlobalSearch: 'globalsearch',
    HtmlProp: {
        ACC_DEP_CONTENT: "accountDependentContent",
        CONTENT_ID: "contentIdentifier"
    },
    VolumeMode: {
        NORMAL: 0,
        // regular audio outputs
        EXT_ABS: 1,
        // absolute value sent to external device - no impact on app
        EXT_UP_DOWN: 2 // external device in charge of actual volume, e.g. via SPDIF

    },
    Event: {
        COVER: 'coverurl',
        AUDIO_TYPE: 'audiotype',
        TIME: 'time',
        PROGRESS_MS: 'progress_ms',
        // new property
        DURATION: 'duration',
        DURATION_MS: 'duration_ms',
        // new property
        DURATION_INTERNAL: "dur_internal",
        // app internal duration property, not adopted by states, but internal logic.
        TIME_INTERNAL: "time_internal",
        // app internal time property, not adopted by states, but internal logic.
        MODE: 'mode',
        TITLE: 'title',
        ARTIST: 'artist',
        ALBUM: 'album',
        STATION: 'station',
        VOLUME: 'volume',
        QUEUE_INDEX: 'qindex',
        REPEAT: 'plrepeat',
        AUDIO_PATH: 'audiopath',
        QUEUE_INDEX_MINISERVER: 'queueIndex',
        AIRPLAY_MINISERVER: 'enableAirPlay',
        SPOTIFY_CONNECT_MINISERVER: 'enableSpotifyConnect',
        ALARM_VOLUME_MINISERVER: 'alarmVolume',
        BELL_VOLUME_MINISERVER: 'bellVolume',
        BUZZER_VOLUME_MINISERVER: 'buzzerVolume',
        TTS_VOLUME_MINISERVER: 'ttsVolume',
        DEFAULT_VOLUME_MINISERVER: 'defaultVolume',
        MAX_VOLUME_MINISERVER: 'maxVolume',
        EQ_SETTINGS_MINISERVER: 'equalizerSettings',
        MASTER_VOLUME: 'mastervolume',
        ZONE_STATE: 'power',
        // on / off / offline
        PLAYER_ID: 'playerid',
        QUEUE_RESTRICTIONS: 'restrictions',
        // I15129 -> since spotify connect the queue may be restricted.
        // used e.g. in the mediathek
        NAME: 'name',
        OWNER: "owner",
       // e.g. in Spotify Playlists
        OWNER_ID: "ownerId",
        // e.g. in Spotify Playlists
        ID: 'id',
        FILE_TYPE: 'type',
        // used internally only
        SYNCED_ZONES: 'syncedzones',
        SYNCED_COLOR: 'syncedcolor',
        // used in sync-events
        PLAYERS: 'players',
        // Settings-Attributes
        AUDIO_DELAY: 'audio_delay',
        MAX_VOLUME: 'max_volume',
        DEFAULT_VOLUME: 'default_volume',
        EQUALIZER: 'equalizer',
        // settings-attributes from the expert mode.
        MAX_VOLUME_LOCKED: "max_volume_locked",
        DEFAULT_VOLUME_LOCKED: "default_volume_locked",
        // used in mediathek for zone-favorites
        SLOT: "slot",
        EMPTY_SLOT_ID: "isAnEmptySlot",
        SOURCE_NAME: "sourceName",
        // introduced for streaming service
        EVENT_TYPE: "eventype" // typo on AS - only one "T". present when a event file is played.

    },
    // IDs for attributes from the expert mode
    ExpertModeId: {
        VM: 407,
        // max volume
        VD: 408 // default volume

    },
    EventAttr: {
        ZONE_STATE: {
            ON: 'on',
            OFF: 'off',
            OFFLINE: 'offline'
        },
        Mode: {
            PLAY: 'play',
            STOP: 'stop',
            PAUSE: 'pause',
            // unused
            RESUME: 'resume' // unused?

        }
    },
    Attr: {
        SERVICE: {
            _: "service",
            USER: "user",
            UID: 'uid',
            ID: 'id',
            CMD: 'cmd',
            ICON: 'iconSrc',
            CFG: 'config',
            NAME: 'name',
            ICON_EXTERN: 'icon',
            CONFIG_ERROR: 'configerror',
            HELPLINK: 'helplink',
            REGISTERLINK: 'registerlink',
            EULA: 'eula',
            ZONES: 'zones'
        },
        Playlist: {
            ID: 'playlistid'
        },
        Container: {
            ITEMS: 'items',
            ERROR: 'error',
            REASON: 'reason',
            ACTION: 'action',
            ActionType: {
                UPDATE: 'update',
                START: 'start',
                // someone starts to work on the container
                CREATE: 'create',
                DELETE: 'delete',
                RENAME: 'rename'
            },
            NAME: 'name'
        },
        Item: {
            TAG: "tag" // e.g. "artist", "album" --> like a search tag, only for result items.

        }
    },
    MediaType: {
        LIBRARY: 'library',
        FAVORITES: 'favorites',
        PLAYLIST: 'playlist',
        SERVICE: 'service',
        INPUT: 'input'
    },
    Comm: {
        CMD: 'command',
        // attribute that contains the command that has been confirmed or did trigger an event
        EVENT_ID: '_event',
        // postfix identifying an event
        RESULT_ID: "_result",
        // postfix identifying an result
        ERROR_ID: "_error",
        // postfix identifying an error
        ROOT_MSG: "LWSS V ",
        // first string of the first message recieved on the socket.
        IDENTIFIER: {
            EVENT: "_event",
            RESULT: "_result",
            ERROR: "_error",
            ERROR_PLAIN: "error"
        }
    },
    // reasons why the edit mode of a container was ended.
    EditEndCause: {
        UNKNOWN: "unknown",
        // it is unclear why the edit mode did end.
        CONFLICT: "conflict",
        // someone else started editing, that's why it was ended.
        MODIFIED: "modified",
        // someone else did already edit this container, thats why it wasn't started
        STOPPED: "stopped",
        // it was stopped by the user.
        DELETED: "deleted" // the container we're editing was deleted by someone else!

    },
    AudioCommands: {
        STATUS: 'status',
        IDENTIFY_SOURCE: 'identifysource',
        // since SAVE_CURRENT_PLAYING Feature
        QUEUE: {
            NEXT: 'queueplus',
            PREV: 'queueminus',
            JUMP_TO: 'queue/',
            ADD: 'queueadd/',
            INSERT: 'queueinsert/',
            INSERT_AND_PLAY: 'queueandplay/',
            UNDO: 'queueundo/',
            REMOVE: 'queueremove/',
            MOVE: 'queuemove/',
            CLEAR: 'queue/clear',
            GET: 'getqueue',
            SAVE_AS_PLAYLIST: 'playlist/save/'
        },
        QUEUE_V2: {
            PLAY_ID: 'queue/play/%s',
            MOVE_BEFORE: 'queue/move/%s/before/%s',
            // behaviour changed in 2021.10.20 - before it did actually move after.
            MOVE_END: 'queue/move/%s/end',
            // move item to the end of the list.
            REMOVE: 'queue/remove/%s'
        },
        SEEK: {
            FORWARD_BY: 'position/+',
            BACKWARD_BY: 'position/-',
            TO: 'position/'
        },
        PLAY: 'play',
        PAUSE: 'pause',
        SHUFFLE: "shuffle",
        REPEAT: {
            PLAIN: "repeat/",
            OFF: 'repeat/0',
            PLAYLIST: 'repeat/1',
            // PLAYLIST_ONCE: 'repeat/2', this doesn't exist in the MediaServer
            TRACK: 'repeat/3'
        },
        VOLUME: {
            SET_TO: "volume/",
            UP: "volup",
            UP_BY: "volume/+",
            DOWN: "voldown",
            DOWN_BY: "volume/-",
            REQUEST: 'volume'
        },
        MASTER_VOLUME: {
            REQUEST: "mastervolume",
            SET_TO: "mastervolume/"
        },
        UNSYNC: "unsync/",
        SYNC: "sync/",
        GET_SYNCED_PLAYERS: "getmysyncedplayers",
        CFG: {
            AUDIO_DELAY: 'audiodelay',
            DEFAULT_VOLUME: 'defaultvolume',
            MAX_VOLUME: 'maxvolume',
            EQUALIZER: 'equalizer'
        },
        LIBRARY: {
            PLAY: 'library/play/'
        },
        PLAYLIST: {
            PLAY: 'playlist/play/'
        },
        FAVORITE: {
            PLAY: 'favoriteplay/'
        },
        ZONE_FAV: {
            GET: "getroomfavs",
            NEXT: 'roomfav/plus',
            PLAY: 'roomfav/play/',
            SAVE: 'roomfav/save/',
            SAVE_LOCAL: 'roomfav/saveid/',
            // 2015.09.15+ - ADD_ZONEFAV_DIRECT
            SAVE_EXTERNAL: 'roomfav/saveexternalid/',
            // 2015.09.15+ - ADD_ZONEFAV_DIRECT
            SAVE_PATH: 'roomfav/savepath/',
            // 2015.09.15+ - ADD_ZONEFAV_DIRECT
            DELETE: 'roomfav/delete/',
            SAVE_INPUT: 'roomfav/saveinput/',
            // 2016.??.?? - AudioserverComp.Feature.INPUT_ZONE_FAVS
            BASE: "roomfavs/",
            SET_PLUS: "setplus/",
            SET_ID: "setid/",
            RENAME: "setname/",
            REORDER: "reorder/",
            ADD: "add/",
            REMOVE: "delete/"
        },
        SERVICE: {
            PLAY: 'serviceplay/',
            ADD: 'serviceplayadd/',
            INSERT: 'serviceplayinsert/',
            INSERT_AND_PLAY: 'serviceplayinsertandplay/'
        },
        LINEIN: {
            PLAY: 'linein'
        },
        SEARCH: {
            PLAY: 'playurl/',
            ADD: 'addurl/',
            INSERT: 'inserturl/',
            INSERT_AND_PLAY: 'insertandplayurl/'
        },
        ANNOUNCEMENT: {
            UPLOAD: "%saudio/cfg/upload/audioupload/add/%s",
            PLAY: "audio/grouped/playuploadedfile/%s/%s" // %s = path of the file to upload | %s = list of playerids to play the file

        }
    },
    PlayType: {
        // the 4 different types an item can be played
        NOW: "now",
        // insert and play right away
        NEXT: "next",
        // insert and play after the current track
        ADD: "add",
        // add to the queue
        REPLACE: "replace" // replace the queue and start playing right away

    },
    Commands: {
        AUDIO_STATUS_ALL: "audio/cfg/getplayersdetails",
        UPD_LVL: "audio/cfg/props/updatelevel",
        SYNC: {
            GET_SYNCED_PLAYERS: 'audio/cfg/getsyncedplayers',
            GET_SYNCED_PLAYERS_ID: 'getsyncedplayers',
            MULTI_UNSYNC: "audio/cfg/unsyncmulti/" // 2016.06.22 unsyncs several groups at once.

        },
        FAVORITES: {
            ADD_PATH: 'audio/cfg/favorites/addpath/',
            ADD_ID: 'audio/cfg/favorites/addid/',
            ADD_EXTERNAL_ID: 'audio/cfg/favorites/addexternalid/',
            DELETE: 'audio/cfg/favorites/delete/',
            RENAME: 'audio/cfg/favorites/rename/'
        },
        SERVICES: {
            GET: "audio/cfg/getservices",
            GET_AVAILABLE: "audio/cfg/getavailableservices",
            CFG: {
                GET: "audio/cfg/servicecfg/",
                SET: "audio/cfg/servicecfg/",
                ADD: "audio/cfg/servicecfg/add/",
                UPDATE: "audio/cfg/servicecfg/update/",
                DELETE: "audio/cfg/servicecfg/delete/",
                GET_LINK: "audio/cfg/servicecfg/getlink/"
            },
            RADIO: {
                ENABLE: 'audio/cfg/tunein/enable',
                DISABLE: 'audio/cfg/tunein/disable'
            }
        },
        SETTINGS: {
            AIRPLAY: {
                ENABLE: "audio/cfg/airplay/enable",
                DISABLE: "audio/cfg/airplay/disable",
                STATE: "audio/cfg/airplay"
            },
            LIBRARY: {
                LIST: "audio/cfg/storage/list",
                ADD: "audio/cfg/storage/add/%s",
                // Base 64 encoded payload
                DELETE: "audio/cfg/storage/delete/%s",
                // ID of the NAS
                EDIT: "audio/cfg/storage/edit/%s/%s" // ID of the NAS, Base64 encoded payload

            },
            SPOTIFY: {
                CONNECT: {
                    ENABLE: "audio/cfg/spotifyconnect/enable",
                    DISABLE: "audio/cfg/spotifyconnect/disable",
                    STATE: "audio/cfg/spotifyconnect"
                }
            },
            EQ: {
                GET: "audio/cfg/geteq",
                SET: "audio/cfg/seteq"
            }
        },
        INPUT: {
            GET: "audio/cfg/getinputs",
            SET: {
                CMD: "audio/cfg/input/",
                NAME: "/rename/",
                ENABLED: "/enabled/",
                INPUT_VOLUME: "/inputvolume/",
                INPUT_VOLUME_FINAL: "/inputvolumesave/",
                TYPE: "/type/"
            }
        },
        SEARCH: {
            SELECTIVE: 'audio/cfg/search',
            BROWSE: 'audio/cfg/searchresult/'
        },
        PLAYLIST: {
            GET: "getplaylists2",
            // used like getservicefolder to get a list of playlists or the content of a playlist
            DELETE: "audio/cfg/playlist/deletelist",
            // delete the playlist itself
            CREATE: "audio/cfg/playlist/create",
            UPDATE: "audio/cfg/playlist/update",
            // update the content of the playlist
            RENAME: "audio/cfg/playlist/rename",
            GET_SERVICE_FOLDER: "audio/cfg/getservicefolder/%s/%s/%s/%i/%i",
            GET_PLAYLIST_2: "audio/cfg/getplaylists2/lms/%s/%s/0/50" // user | audioPath/id

        },
        SERVICE_MODIFY: {
            FOLLOW: "audio/cfg/follow/%s/%s",
            // service identifier (spotify)/user, audiopath
            UNFOLLOW: "audio/cfg/unfollow/%s/%s",
            // service identifier (spotify)/user, audiopath
            IS_FOLLOWING: "audio/cfg/isfollowed/%s/%s" // service identifier (spotify)/user, audiopath
        },
        SERVICE_ASSIGN_ZONE_SPOTIFY: "audio/cfg/servicecfg/set/spotify/%s/asdefault/player/%d", //userId + playerId
        SERVICE_ASSIGN_ZONE_SOUNDSUIT: "audio/cfg/servicecfg/set/soundsuit/%s/asdefault/player/%d", //userId + playerId
        GLOBAL_SEARCH: {
            BASE_CMD: 'audio/cfg/globalsearch/',
            DESCRIBE: 'audio/cfg/globalsearch/describe'
        },
        COPY_ZONE_FAVS: 'audio/cfg/roomfavs/%s/copy/%s',
        Container: {
            Update: {
                // affects the content of a container such as a playlist
                START: "start",
                MOVE: "move:",
                REMOVE: "remove:",
                ADD_ITEM: "additem:",
                ADD_BROWSABLE: "addbrowsable:",
                FINISH: "finish",
                FINISH_NO_CHANGES: "finishnochanges",
                MOVE_BY_ID: "moveById/%s/before/%s",
                // moves an item with an unique id before another item with unique id.
                MOVE_TO_END: "moveById/%s/end",
                // moves an item with a unique id to the end of the list
                REMOVE_BY_ID: "removeById/%s" // removes an item based on the uniqueId attribute.

            }
        },
        RADIOS: {
            GET: "audio/cfg/getradios"
        },
        CUSTOM_STREAM: {
            ADD: "audio/cfg/radios/add/%s/%s", // {name}/{URIEncoded-Stream-URL}
            DELETE: "audio/cfg/radios/del/%s", // {id} (as returned in the create or in the getradios response)
            UPDATE: "audio/cfg/radios/modify/%s/%s/%s", // {id}/{name}/{URIEncoded-Stream-URL}
            SET_COVER: "audio/cfg/radios/coverurl/%s", //{id} --> provide image via HTTP-PUT as payload.
        },
        GETKEY: "audio/cfg/getkey",
        GETMAC: "audio/cfg/mac",
        INSTALLUPDATE: "audio/cfg/installupdate",
        LIBRARY: {
            SCAN_STATUS: 'audio/cfg/scanstatus',
            RESCAN_ALL: "audio/cfg/rescan",
            RESCAN_SPECIFIC: "audio/cfg/rescan/%s" // %s = folder

        },
        GROUP_PLAY: 'audio/grouped/play/%s',
        // sends play to all players provided (%s = player ids separated by comma)
        GROUP_PAUSE: 'audio/grouped/pause/%s',
        // sends pause to all players provided (%s = player ids separated by comma)
        DynamicGroup: {
            // single command that covers create/join/leave/dissolve
            // %s = groupId (new for new group), %s = playerIds separated by comma (empty if dissolve)
            UPDATE: 'audio/cfg/dgroup/update/%s/%s'
        }
    },
    // The parent info will be appended to all replace queue and play commands.
    ParentInfo: {
        PATH: "/parentpath/",
        ID: "/parentid/"
    },
    StateEnums: {
        REPEAT: {
            OFF: 0,
            PLAYLIST: 1,
            // PLAYLIST_ONCE: 2, this doesn't exist in the MediaServer
            TRACK: 3
        },
        SHUFFLE: {
            OFF: 0,
            ON: 1
        }
    },
    ResultId: {
        ROOM_FAV: "getroomfavs_result"
    },
    PopupMessageId: {
        UPNPSEARCH: "upnpsearch",
        INITSPOTIFY: "initspotify",
        INITGOOGLE: "initgoogle",
        DELETESPOTIFY: "deletespotify",
        DELETEGOOGLE: "deletegoogle",
        SPOTIFYFAVORITE: "spotifyfavorite",
        RECONFIGURED: "reconfigured",
        BIGFILELIST: "bigfilelist",
        ADDLIMIT: "addlimit",
        NOINTERNET: "nointernet",
        BADCONN: "badconn",
        TTSERROR: "ttserror",
        EVENTFILERROR: "eventfileerror",
        RESCANNING: "rescanning",
        BADFILE: "badfile",
        SLOWINTERNET: "slowinternet",
        SPOTIFYINUSE: "spotifyalreadyinuse",
        UPDATEAVAILABLE: "updateavailable"
    },
    MediaContentType: {
        LIBRARY: 'Library',
        ZONE_FAVORITES: 'ZoneFavorites',
        FAVORITES: 'Favorites',
        PLAYLISTS: 'Playlists',
        SERVICE: 'Service',
        SOUNDSUIT: 'Soundsuit',
        SEARCH: 'Search',
        QUEUE: 'Queue',
        LINEIN: 'LineIn',
        PLAYING: 'Playing',
        VOICE_RECORDER: 'VoiceRecorder'
    },
    // key of section provided by the audioserver when globalsearch_results arrive.
    SearchResultType: {
        PLAYLISTS: "playlists"
    },
    Service: {
        LOCAL: 'local',
        LMS: 'lms',
        SPOTIFY: 'spotify',
        GOOGLE: 'googlemusic',
        AMAZON: 'amazon',
        SOUNDSUIT: "soundsuit"
    },
    Search_Result: {
        SPOTIFY: 'spotify',
        TUNE_IN: 'tunein',
        LOCAL: 'local'
    },
    Target: {
        LIBRARY: 'library',
        RADIO: 'radio',
        LMS: 'lms'
    },
    SearchLoc: {
        ALL: 'all',
        RADIO: 'radio',
        SPOTIFY: 'spotify',
        LIBRARY: 'library',
        LMS: 'lms'
    },
    // well known radio "types" from tune-in
    Radios: {
        LOCAL: 'local',
        WORLD: 'world',
        MUSIC: 'music',
        NEWS: 'news',
        PODCAST: 'podcast',
        CUSTOM: 'custom'
    },
    Tag: {
        NONE: 'none',
        ARTIST: 'artist',
        ALBUM: 'album',
        TRACK: 'track',
        PLAYLIST: 'playlist',
        GENRE: 'genre',
        STATION: 'station',
        SHOW: "show",
        EPISODE: "episode"
    },
    Spotify: {
        TYPES: {
            // well known spotify "types"
            LIBRARY: "-1",
            // This is a custom type defined by the app!
            FEATURES: "0",
            NEW_RELEASE: "1",
            CATEGORIES: "2",
            MY_PLAYLISTS: "3",
            TRACKS: "4",
            ALBUMS: "5",
            ARTISTS: "6",
            PODCASTS: "7"
        },
        PathParts: {
            FavSongs: {
                PREFIX: "spotify@",
                SUFFIX: ":user:collection"
            }
        }
    },
    Soundsuit: {
        TYPES: { MOODS: "moods", SCHEDULES: "schedules" },
        PathParts: {
            FavSongs: { //TODO-woessto: is this used somewhere?
                PREFIX: "soundsuit@",
                SUFFIX: ":user:collection"
            }
        }
    },
    ServiceCfgErrorCode: {
        NONE: 0,
        WRONG_CREDENTIALS: 4,
        DUPLICATE_ACCOUNT: 5,
        NON_EXISTENT_ACCOUNT: 8
    },
    ServiceHelpLinks: {
        SPOTIFY: 'https://www.loxone.com/help/musicserver-spotify',
        SOUNDSUIT: 'https://soundsuit.fm/',
        GOOGLE: 'https://www.loxone.com/help/musicserver-googlemusic'
    },
    ServerState: {
        MUSICSERVER_REBOOTING: -5,
        INVALID_ZONE: -3,
        NOT_REACHABLE: -2,
        // Network unreachable
        UNKNOWN: -1,
        OFFLINE: 0,
        INITIALIZING: 1,
        // Websocket connection established (Miniserver -> Audioserver)
        ONLINE: 2 // First communication (setting config to Audioserver)

    },
    ClientState: {
        AUDIO_ZONE_REBOOTING: -5,
        // power state
        NOT_REACHABLE: -2,
        OFFLINE: 0,
        INITIALIZING: 1,
        ONLINE: 2
    },
    PlayState: {
        UNKNOWN: -1,
        STOPPED: 0,
        PAUSED: 1,
        PLAYING: 2
    },

    /**
     * If it's 0 everything is fine. if not, it's either booting up - in this case it provides the current state - or if
     * the serverState is not reachable, it provides info on where it did hang and what could be the causing error
     */
    ServerConnState: {
        IDLE: 0,
        // Right now everything is fine - uninitialized
        NO_PING: 1,
        // the Music Server does not respond to pings from the Miniserver
        NO_WEBINTERFACE: 2,
        // the webinterface is not yet ready - but the server does already respond to pings
        NO_COMM_SERVICE: 3,
        // it's reachable on the network but its communication services are not up
        NO_MUSIC_SERVICE: 4,
        // the communication services are okay, but the Music Services have not been booted.
        READY: 10 // everything is up and running

    },
    GROUP_COLORS: ["#F87350", "#75BD6E", "#EB78A2", "#57A6F6", "#76D8E5", "#FCC069", "#2D9788", "#BE7DD1", "#AD9993", "#7F939E"],
    LineInMap: {
        0: Icon.AudioZone.LINE_IN.LINE_IN,
        1: Icon.AudioZone.LINE_IN.CD_PLAYER,
        2: Icon.AudioZone.LINE_IN.COMPUTER,
        3: Icon.AudioZone.LINE_IN.IMAC,
        4: Icon.AudioZone.LINE_IN.IPOD,
        5: Icon.AudioZone.LINE_IN.MOBILE,
        6: Icon.AudioZone.LINE_IN.RADIO,
        7: Icon.AudioZone.LINE_IN.SCREEN,
        8: Icon.AudioZone.LINE_IN.TURNTABLE
    },
    NAS_ERROR: {
        NONE: 0,
        NON_EXISTENT: 1,
        UNREACHABLE: 2,
        ADD_SELF: 9,
        DUPLICATE: 10,
        FOLDER_UNREACHABLE: 11,
        INVALIDE_CREDS: 12
    },
    RATING: {
        HIDDEN: -999,
        DEFAULT: 0
    },
    CUSTOMIZATION_KEYS: {
        APPEARANCE: "audioserverCustomization_11.2.2",
        CURRENT_SPOTIFY_ACC_ID: "currentSpotifyAccUid",
        LAST_PLAYED: "lastPlayedItems_11.2.2",
        SEARCH_HISTORY: "searchHistory"
    },
    FAVORITE_TYPES: {
        TUNEIN: "tunein",
        LMS_PLAYLIST: "playlist",
        LMS_LINEIN: "linein",
        LIB_FOLDER: "library_folder",
        LIB_TRACK: "library_track",
        LIB_PLAYLIST: "library_playlist",
        SPOTIFY_PLAYLIST: "spotify_playlist",
        SPOTIFY_ALBUM: "spotify_album",
        SPOTIFY_SHOW: "spotify_show",
        SPOTIFY_EPISODE: "spotify_episode",
        SPOTIFY_ARTIST: "spotify_artist",
        SPOTIFY_TRACK: "spotify_track",
        SPOTIFY_COLLECTION: "spotify_collection",
        CUSTOM_STREAM: "custom_stream"
    },
    URI_IDS: {
        CONTENT_TYPE: {
            TUNE_IN: "tunein",
            SPOTIFY: "spotify",
            SOUNDSUIT: "soundsuit",
            LIBRARY: "library",
            PLAYLIST: "playlist"
        },
        SERVICE: {
            TUNE_IN: {
                GENRE: 'genre',
                STATION: 'station'
            },
            SPOTIFY: {
                ARTIST: 'artist',
                ALBUM: 'album',
                TRACK: 'track',
                PLAYLIST: 'playlist',
                SHOW: 'show',
                //e.g. spotify podcast
                EPISODE: "episode" // e.g. one episode of a podcast series

            },
            LIBRARY: {
                FOLDER: 'folder',
                TRACK: 'track',
                PLAYLIST: 'playlist'
            }
        }
    },
    QueueAction: {
        MOVE: "move",
        REMOVE: "remove",
        INSERT: "insert"
    },
    MAX_ZONE_FAVS: 8,
    V2_MAC_PRFX: "504F94F0",
    COMPACT_MAC_PRFX: "504F94D0",

    CustomRadioErrors: {
        NONE: 0,
        INVALID_URL: -1,
        FS_ERROR: -2,
        ID_NOT_FOUND: -3,
        NO_COVER: -4
    },

    DisabledFeature: { // if bit is set, a feature is disabled
        BLUETOOTH: 4,
        EQ: 16
    },

    SpeakerConfig: { // info about connected hardware
      BLUETOOTH_SUPPORTED: 2048
    },

    ParameterConnections: {
        BLUETOOTH: 512
    }
};
